a:link {
    text-decoration: none;
}

.game {
    display: flex;
    width: 1080px;
    padding: 24px 16px;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: #F8F8F8;
    box-sizing: border-box;
}

.game:hover {
    transition: 0.3s;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.game-matchup {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
}

.game-club {
    display: flex;
    width: 280px;
    padding: 10px;
    align-items: center;
    gap: 10px;
}

.game-club p{
    text-align: center;
}

.club-logo-frame {
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.club-logo-frame img {
    width: 40px;
    height: 40px;
    object-fit: contain;   
}

.icon-frame img {
    width: 32px;
    height: 32px;
    object-fit: contain;   
}

.game:hover .icon-frame img{
    width: 40px;
    height: 40px;
    transition: 0.3s;
}

.competition {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    max-width: 320px;
}

.game-date{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.game-date p {
    font-size: 16px;
}

.to-ticker {
    display: flex;
    width: 80px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}