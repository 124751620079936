p {
    color: var(--Black, #000);
    font-family: "ivyora-display", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: normal;
    margin: 0;
}

.container-web {
    display: flex;
    padding: 64px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.text-event {
    font-weight: 700;
    margin: 0;
}

.text-mini {
    font-size: 12px;
    font-weight: 700;
}


.container-goal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.headline-row-goal {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.eventgoal-container {
    color: white;
}

.eventgoal-container p {
    color: inherit;
}

.event-goal {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.score-goal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-goal {
    display: flex;
    padding-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-grow: 1;
    align-self: stretch;
}

.player-name-goal {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.player-name-goal p {
    font-size: 24px;
}

.player-nachname {
    font-weight: 700;
}

.player-image {
    display: flex;
    width: 180px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.container-substitution {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.container-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.player-image-sub {
    display: flex;
    width: 120px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-image-sub img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.icon {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: flex-end;
    align-items: center;
}

.container-club {
    display: flex;
    width: 360px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
}

.logo-frame {
    display: flex;
    width: 120px;
    height: 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-frame img {
    width: 120px;
    height: 120px;
    object-fit: contain;   
}

.container-game-details {
    display: flex;
    width: 320px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
}

.game-header {
    display: flex;
    width: 1080px;
    padding: 32px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
}